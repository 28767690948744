export function transformUser(user) {
  const mlsCredential = user.mls_credential || {};
  const mls = user.mls || {};

  return {
    id: user.id,
    jwt: user.jwt,
    city: user.city || "",
    state: user.state || "",
    name: user.name || "",
    email: user.email || "",
    phone: user.phone_number || "",
    initials: user.initials || "",
    avatarUrl: user.avatar_url || "",
    office: user.company_name || "",
    ssoParams: user.sso_params,
    mlsCode: mlsCredential.code,
    mlsName: mlsCredential.name,
    mlsLat: mls.address ? mls.address.lat : null,
    mlsLon: mls.address ? mls.address.lon : null,
    mls: mls,
    lwaJwt: user.lwa_jwt,
    isInactive:
      mlsCredential.active === false || mlsCredential.active === undefined,
    isLoggedInAs: false
  };
}
