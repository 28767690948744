export const initial = {
  id: "",
  jwt: null,
  city: "",
  state: "",
  name: "",
  email: "",
  phone: "",
  initials: "",
  avatarUrl: "",
  office: "",
  ssoParams: "",
  mlsCode: "",
  mlsName: "",
  mlsLat: null,
  mlsLon: null,
  lwaJwt: null,
  isInactive: false,
  isLoggedInAs: false
};

export function currentUser(state = initial, { type, payload }) {
  switch (type) {
    case "SIGN_IN_USER_SUCCEEDED":
    case "GET_CURRENT_USER_SUCCEEDED":
      return { ...state, ...payload.user };
    case "UPDATE_USER_PHOTO_SUCCEEDED":
      return { ...state, avatarUrl: payload };
    case "LOGOUT":
      return initial;
    default:
      return state;
  }
}
